<template>
  <div class="information">
    <a-form
      :label-col="{ span: 6 }"
      :wrapper-col="{ span: 16 }"
      layout="vertical"
    >
      <!--      </a-form-item>-->
      <a-form-item label="行业" class="mt-10 industry">
        <a-textarea
          v-model:value="form.industry"
          placeholder="请输入联系人行业"
          @blur="handleBlur('industry')"
        />
      </a-form-item>
      <a-form-item label="部门" class="mt-10 department">
        <a-textarea
          v-model:value="form.department"
          placeholder="请输入联系人部门"
          @blur="handleBlur('department')"
        />
      </a-form-item>
      <a-form-item label="职位" class="mt-10 position">
        <a-textarea
          v-model:value="form.position"
          placeholder="请输入联系人职位"
          @blur="handleBlur('position')"
        />
      </a-form-item>

      <a-form-item label="学历" class="interaction education">
        <a-select
          v-model:value="form.education"
          placeholder="请选择联系人学历"
          @blur="(value, option) => handleBlur('education')"
        >
          <a-select-option
            v-for="item in educationList"
            :key="item.itemCode"
            :value="item.itemCode"
          >
            {{ item.itemNameCn }}
          </a-select-option>
        </a-select>
      </a-form-item>

      <a-form-item label="毕业院校" class="mt-10">
        <a-input
          v-model:value="form.graduatedFrom"
          placeholder="请输入联系人毕业院校"
          @blur="handleBlur('graduatedFrom')"
        />
      </a-form-item>

      <a-form-item label="星座" class="interaction">
        <a-select
          v-model:value="form.constellation"
          placeholder="请选择联系人星座"
          @blur="(value, option) => handleBlur('constellation')"
        >
          <a-select-option
            v-for="item in constellationList"
            :key="item.itemCode"
            :value="item.itemCode"
          >
            {{ item.itemNameCn }}
          </a-select-option>
        </a-select>
      </a-form-item>

      <div class="row">
        <div class="socialLinks">社交链接</div>
        <a-tooltip placement="topRight">
          <template #title>
            <p>
              您可以将该联系人的抖音、知乎、微博等社交主页的链接粘贴到这里；后续可以通过此处一键访问。
            </p>
          </template>
          <QuestionCircleOutlined style="color: #ff7b00" />
        </a-tooltip>
      </div>

      <div class="zhuhuLink mt-10">
        <div class="zhuhu">
          <img src="@/assets/images/box/zhihui.png" alt="" class="zhihuiImg" />
        </div>
        <a-form-item label="" class="zhuhuItem">
          <a-input
            v-model:value="form.zhiHuUrl"
            placeholder="添加/粘贴知乎链接"
            @blur="handleBlur('zhiHuUrl')"
          />
        </a-form-item>
        <a-tooltip>
          <template #title>一键访问</template>
          <img
            class="link-icon"
            src="@/assets/images/box/link-icon.png"
            @click="openLink(form.zhiHuUrl)"
          />
        </a-tooltip>
      </div>
      <div class="douLink mt-10">
        <div class="dou">
          <img src="@/assets/images/box/douhui.png" alt="" class="douhuiImg" />
        </div>
        <a-form-item label="" class="douItem">
          <a-input
            v-model:value="form.douYinUrl"
            placeholder="添加/粘贴抖音链接"
            @blur="handleBlur('douYinUrl')"
          />
        </a-form-item>
        <a-tooltip>
          <template #title>一键访问</template>
          <img
            class="link-icon"
            src="@/assets/images/box/link-icon.png"
            @click="openLink(form.douYinUrl)"
          />
        </a-tooltip>
      </div>
      <div class="weiLink mt-10">
        <div class="wei">
          <img src="@/assets/images/box/weihui.png" alt="" class="weihuiImg" />
        </div>
        <a-form-item label="" class="weiItem">
          <a-input
            v-model:value="form.weiboUrl"
            placeholder="添加/粘贴微博链接"
            @blur="handleBlur('weiboUrl')"
          />
        </a-form-item>
        <a-tooltip>
          <template #title>一键访问</template>
          <img
            class="link-icon"
            src="@/assets/images/box/link-icon.png"
            @click="openLink(form.weiboUrl)"
          />
        </a-tooltip>
      </div>
    </a-form>
  </div>
</template>

<script>
import { Form, message, Select, Popover, Tooltip } from 'ant-design-vue';
import { QuestionCircleOutlined } from '@ant-design/icons-vue';
import { onMounted, reactive, toRefs } from 'vue';
import contactAll from '@/api/contactAll';
import { useRoute } from 'vue-router';
import { local } from '@/utils/storage';
// import IssAddGroup from "@/views/contacts/contactAall/addGroup";
// import { randoms } from '@/utils';
// import IssPulse from "@/components/pulse";
// import {emailRegExp, mobileRegExp} from "@/utils/validation";

export default {
  components: {
    AForm: Form,
    AFormItem: Form.Item,
    ASelect: Select,
    ASelectOption: Select.Option,
    // APopover: Popover,
    ATooltip: Tooltip,
    // ARow:Row,
    // ACol:Col,
    // IssAddGroup,
    // IssPulse,
    QuestionCircleOutlined,
  },
  setup() {
    const route = useRoute();
    const { id } = route.params;
    const state = reactive({
      visibleAddGroup: false,
      visiblePulsePage: false,
      constellationList: [],
      educationList: [],
    });
    const form = reactive({
      userId: '',
      industry: '',
      department: '',
      education: null,
      position: '',
      graduatedFrom: '',
      constellation: null,
      zhiHuUrl: '',
      douYinUrl: '',
      weiboUrl: '',
    });
    // resetFields validate
    const { validateInfos } = Form.useForm(form, {
      //  { max: 15, message: '标题长度不能超过15' }
      industry: [{ max: 48, message: '字符长度不能超过48' }],
      department: [{ max: 48, message: '字符长度不能超过48' }],
      position: [{ max: 48, message: '字符长度不能超过48' }],
      zhiHuUrl: [{ type: 'url', message: '请输入正确的URL地址' }],
      douYinUrl: [{ type: 'url', message: '请输入正确的URL地址' }],
      weiboUrl: [{ type: 'url', message: '请输入正确的URL地址' }],
    });

    const perSonalInfo = () => {
      contactAll.getContactBaseInfo('', id).then(res => {
        if (res) {
          // 把数据存储一份到local
          local.set('detailslist', res);
          Object.assign(form, res);
        }
      });
    };

    // 学历下拉
    contactAll.getTree('', { code: 'EDUCATION' }).then(res => {
      state.educationList = res;
    });
    // 星座下拉
    contactAll.getTree('', { code: 'CONSTELLATION' }).then(res => {
      state.constellationList = res;
    });

    onMounted(() => {
      perSonalInfo();
    });

    return {
      form,
      validateInfos,
      ...toRefs(state),
      handleBlur(type) {
        // validate().then(() => {
        //   console.log('handleBlur详细信息');
        // });
        console.log('type', type);
        let data = local.get('detailslist');
        // 处理有内容或失焦保存,没有内容是跳过
        for (let key in data) {
          if (key === type) {
            if (data[key] !== form[key]) {
              // 接口
              contactAll.contactUpata('', { ...form }).then(res => {
                if (res) {
                  message.success('操作成功');
                  perSonalInfo();
                }
              });
            } else {
              continue;
            }
          }
        }
      },
      openLink(url) {
        if (url) {
          window.open(url);
        } else {
          message.info('链接不存在');
        }
      },
    };
  },
};
</script>

<style scoped lang="less">
.information {
  margin: 16px 20px;
  //max-height: 662px;
  max-height: calc(100vh - 172px);
  overflow: auto;
  padding-right: 8px;
  padding-left: 2px;
}
.addGroup,
.addNetworking,
.phone,
.email {
  text-align: right;
}
.phone {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 12px;
  .addPhone {
    cursor: pointer;
    color: #ff9634;
  }
}

.ant-input {
  background-color: #f5f6fa;
  border: 1px solid #ffffff;
}
.phoneCalss
  /deep/
  .ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  background-color: #f5f6fa;
  border: 1px solid #ffffff;
}
.phoneCalss /deep/ .ant-select {
  color: #97a5ce;
}
.phoneCalss /deep/ .ant-col-16 {
  max-width: 100%;
}
.ant-form-item {
  margin-bottom: 0px;
}
.phoneCalss {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 5px;
  .phoneCalssLeft {
    width: 69%;
  }
  .address {
    width: 100%;
  }
  .phoneCalssRight {
    width: 30%;
  }
  .delete {
    cursor: pointer;
  }
}
.groupCalss {
  background-color: #f5f6fa;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  padding: 0px 8px 6px;
  margin-top: 10px;
  .groupContent {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    //border: 1px solid #F2F3F7;
    //background: #FFFFFF;
    //padding: 8px 12px;
    border-radius: 4px;
    margin-right: 8px;
    margin-top: 8px;

    .groupDel {
      cursor: pointer;
      border: 1px solid #f2f3f7;
      background: #ffffff;
      padding: 12px;
      border-radius: 4px;
    }
    .name {
      border: 1px solid #f2f3f7;
      background: #ffffff;
      padding: 5px 12px;
      border-radius: 4px;
    }
  }
}

.mt-10 /deep/ .ant-col-16 {
  max-width: 100%;
}
//.position, .department, .industry {
//  margin-top: 0px;
//}

.socialLinks {
  margin: 18px 0px;
  color: #6b7592;
}
.zhuhuLink,
.douLink,
.weiLink {
  display: flex;
  // justify-content: space-between;
  align-items: center;
  width: 100%;
  .zhuhu,
  .dou,
  .wei {
    width: 8%;
  }
  .zhuhuItem,
  .douItem,
  .weiItem {
    width: 85%;
  }

  .link-icon {
    width: 20px;
    height: 20px;
    margin-left: 8px;
    object-fit: cover;
    cursor: pointer;
  }
}
.douLink {
  margin: 10px 0px;
}
.interaction {
  margin-top: 10px;
}
.education {
  margin-top: 0px;
}

.interaction
  /deep/
  .ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  background-color: #f5f6fa;
  border: 1px solid #ffffff;
}
.interaction /deep/ .ant-col-16 {
  max-width: 100%;
}

.row {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
</style>
